#contact {
    text-align: center;
    background: linear-gradient(90deg, #69CC8F, #b5efc8);
    padding: 100px 20px 120px;
    color: #fff;
}

.c-heading {
    font-size: 52px;
    font-weight: 500;
}

.c-form {
    color: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 10px;
}

.file-button{
  margin-top: 20px; 
  background-color: #fff;
  border-radius: 8px;
  border: none;
  padding: 10px 20px;
  font-size: 18px; 
  cursor: pointer;
  transition: background-color 0.3s;
  border-radius: 8px;
}

.c-form-inputs {
    margin-top: 20px; 
    border: none;
    font-size: 18px; 
    padding: 5px; 
    width: 50%; 
    border-radius: 8px;
  }
  
  .c-form-message {
    margin-top: 20px; 
    font-size: 18px; 
    padding: 10px; 
    width: 50%; 
    height: 200px; 
    border: none;
    border-radius: 8px;
  }
  
  button {
    margin-top: 20px; 
    background-color: #5BBA7F;
    border: none;
    padding: 10px 20px;
    font-size: 18px; 
    cursor: pointer;
    border-radius: 8px;
  }
  
  button:hover {
    background-color: #08bd4e;
  }

.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  z-index: 10000;
}

.popup p {
  margin: 0;
  font-size: 16px;
  color: #333;
}

.popup button {
  margin-top: 10px;
  padding: 8px 16px;
  background-color: #5BBA7F;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.popup button:hover {
  background-color: #08bd4e;
}

  
  
  
  
  