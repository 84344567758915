.n {
  padding: 0 100px 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  /* height: 90vh; */
}

.n-heading {
  font-size: 52px;
  font-weight: 500;
  padding: 30px 0;
}

.news {
  display: flex;
  justify-content: space-around;
}

.news-card {
  width: 300px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 10px;
  margin: 10px;
  padding: 10px;
  cursor: pointer;
  transition: transform 0.2s;
  padding: 0 50px;
}

.news-card:hover {
  transform: scale(1.05);
}

.news-image {
  width: 80%;
  border-radius: 10px;
  height: 60%;
  object-fit: cover;
}

.news-content {
  padding: 10px;
}

.news-title {
  font-size: 1.2rem;
  font-weight: bold;
  margin: 12px;
}

.news-description {
  font-size: 0.9rem;
}

.news-link {
  text-decoration: none;
  color: #0077cc;
  font-weight: bold;
  display: block;
  margin-top: 10px;
}

.news-link:hover {
  text-decoration: underline;
  color: #0055aa;
}

@media screen and (max-width: 768px) {
  .n {
    padding: 20px 30px;
    /* height: auto; */
  }

  .n-heading {
    font-size: 30px;
    padding: 10px;
  }

  .news {
    flex-direction: column;
    align-items: center;
  }

  .news-card {
    width: 100%;
    padding: 10px;
  }

  .news-image {
    width: 100%;
    border-radius: 10px;
    height: 180px;
    object-fit: cover;
  }
}

@media screen and (min-width: 769px) {
  .n {
    height: 90vh;
  }
}
