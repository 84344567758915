.FeedPage {
    flex-direction: column;
    align-items: center;
    padding-left: 16px;
}

.TopSectionFeed {
    margin-bottom: 20px;
    padding-top: 20px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
}

.video {
    max-width: 100%;
    width: auto;
    height: 100%;
    object-fit: contain;
}

.BottomSection {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ButtonsContainer {
    display: flex;
    gap: 10px;
}


@media (min-width: 700px) {
    .TopSection {
        margin-bottom: 0;
    }

    .Button {
        margin-right: 10px;
    }
}

.visible {
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
}

.hidden {
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease-in-out;
}

.progress-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    padding-top: 40px;
    width: 90%;
    /* Use a percentage for responsiveness */
    max-width: 600px;
    /* Limit the maximum width */
    margin: auto;
}

.circular-progress-text {
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 90;
    color: white;
}

.linear-progress-bar {
    width: 4vw;
    max-width: 12px;
    height: 60vh;
    max-height: 500px;
    background-color: #eee;
    border-radius: 10px;
    margin: 20px;
    position: relative;
    overflow: visible;
}

.linear-progress {
    width: 100%;
    background-color: #4caf50;
    position: absolute;
    bottom: 0;
    transition: height 0.5s ease;
    border-radius: 5px;
}

.progress-ball {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #000000;
    opacity: 0.7;
    left: 50%;
    transform: translateX(-50%);
    transition: bottom 0.5s ease;
    font-weight: 700;
}

.react-circular-progressbar-path {
    transition: stroke-dashoffset 0.5s ease 0s;
}

.reps-accuracy {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
}

.message-box {
    position: absolute;
    bottom: 20px;
    left: 75%;
    transform: translateX(-50%);
    background-color: #000;
    color: #fff;
    padding: 10px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.9;
    transition: opacity 333s ease;
    border: 3px solid #33c447;
    z-index: 25;
    width: 40%;
}

.message-content {
    font-weight: 600;
    font-size: 28px;
    z-index: 9999;
}

.CircularProgressbar {
    filter: none;
}