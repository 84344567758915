.ExercisePage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80vh;
  width: 100%;
  gap: 100px;
}

h1 {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.TopSection {
  margin-bottom: 20px;
}

.ExerciseImage {
  width: 100%;
  max-width: 100%;
  height: auto;
}

.BottomSection {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ButtonsContainer {
  display: flex;
  gap: 10px;
}

@media (min-width: 700px) {
  .TopSection {
    margin-right: 20px;
    margin-bottom: 0;
  }

  .BottomSection {
    align-items: flex-start;
  }

  .ButtonsContainer {
    flex-direction: row;
  }

  .Button {
    margin-right: 10px;
  }
}

.ModalContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ModalContent {
  background-color: white;
  width: 50%;
  padding: 20px;
  border-radius: 8px;
}

@media (max-width: 700px) {
  .ModalContent {
    width: 80%;
  }
}