.f{
    position: sticky;
    top: 0;
    left: 0;
    z-index: 8999;
    display: flex;
    justify-content: space-between;
    padding: 8px 16px;
    font-weight: 500;   
    box-shadow: 0 1px 6px rgba(169, 188, 218, .65);
    /* background: linear-gradient(90deg, #14A2B1, #68CD8F); */
    /* color: white; */
}

.f-left{
    font-size: 12px;
}

.f-right{
    font-size: 14px;
    padding-right: 40px;
    display: flex;
    flex-direction: column;
    text-align: right;
}

.f-right:hover{
    cursor: pointer;
}

.f-right-content{
    padding: 4px 20px;
}