.MainDivModal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.ProgressBarDivExercise {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    max-width: 50%;
    width: 100%;
    max-height: 80%;
    overflow: auto;
}

.dashboard-button-container {
    display: flex;
    justify-content: center;
    padding-top: 16px;
    gap: 12px;
}

.modal-header {
    display: flex;
    justify-content: flex-end;
}

.close-button {
    background: none;
    border: none;
    font-size: 12px;
    cursor: pointer;
    margin-top: 0;
}

.close-button:hover {
    background: rgba(0, 1, 0, 0.1);
}