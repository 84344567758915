.Upcoming1 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 22px;
  padding: 0px;
  box-sizing: border-box;
  width: 100%;
}

.Upcoming2 {
  text-align: left;
  white-space: pre-wrap;
  color: rgba(0, 0, 0, 1);
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  letter-spacing: 0px;
  text-decoration: none;
  text-transform: none;
}

.Event1 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 15px;
  padding: 0px;
  box-sizing: border-box;
  align-self: stretch;
}

.Frame42 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0px;
  box-sizing: border-box;
}

.Today {
  text-align: left;
  white-space: pre-wrap;
  color: rgba(159, 159, 159, 1);
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  letter-spacing: 0px;
  text-decoration: none;
  line-height: 28px;
  text-transform: none;
}

.YourFirstDayAtMoonBa {
  text-align: left;
  white-space: pre-wrap;
  color: rgba(0, 0, 0, 1);
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  letter-spacing: 0px;
  text-decoration: none;
  line-height: 28px;
  text-transform: none;
}

.Rectangle10 {
  background-color: rgba(218, 218, 218, 1);
  align-self: stretch;
  height: 2px;
}