.account-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}

.user-info {
  display: flex;
  align-items: center;
}

.user-image img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 20px;
}

.language-preference {
  margin-top: 10px;
}

.pp {
  font-size: large;
}

.logout-btn {
  padding-top: 30px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}