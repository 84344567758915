/* .container {
    position: absolute;
    width: 100%;
    max-height: 100%;
} */

.input_video {
    display: none;
    position: absolute;
    transform: scale(-1, 1);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.output_canvas {
    width: 200%;
    height: 100vh;
    position: relative;

}