.TypeQuestSidebarOpen {
    background-color: rgba(255, 255, 255, 1);
    display: flex;
    flex-direction: column;
    width: 100vw;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0px;
    box-sizing: border-box;
}

.Page {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0px;
    box-sizing: border-box;
    align-self: stretch;
    height: 100%;
}

.Sidebar1 {
    align-self: stretch;
    width: 290px;
}

.Content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 48px;
    height: 100vh;
    overflow: auto;
    padding-left: 10px;
    margin-right: 0px;
    margin-left: 90px;
    padding-right: 10px;
}

.Content::-webkit-scrollbar {
    display: none;
}

@media only screen and (max-width: 1000px) {
    .Content {
        margin: 0 15px 30px 15px;
        height: 140vh;
    }
}

.NavBar1 {
    align-self: stretch;
    height: 80px;
}

.Sec1 {
    padding-top: 0%;
    align-self: stretch;
}

@media only screen and (max-width: 600px) {
    .Sec1 {
        padding: 20px 18px;
        padding-top: 0%;
    }
}

.WelcomeText {
    font-weight: 700;
    font-size: 28px;
}

.FeedbackBar1 {
    align-self: stretch;
}

.Sec2 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    box-sizing: border-box;
    align-self: stretch;
    gap: 20px;
}

@media only screen and (max-width: 1040px) {
    .Sec2 {
        flex-direction: column;
    }
}

.Sec3 {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
    box-sizing: border-box;
    align-self: stretch;
}

@media only screen and (max-width: 1000px) {
    .Sec3 {
        padding: 0 0 7px 12px;
    }
}

.UpcomingEvents {
    text-align: left;
    white-space: pre-wrap;
    color: rgba(0, 0, 0, 1);
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    letter-spacing: 0px;
    text-decoration: none;
    text-transform: none;
}

.Items-doc {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 30px;
    padding: 0px;
    box-sizing: border-box;
    align-self: stretch;
}

@media only screen and (max-width: 1000px) {
    .Items-doc {
        flex-direction: row;
        padding-left: 12px;
        width: 94%;
    }
}

.Sec4 {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
    align-self: stretch;
    height: 406px;
}

.DocExerciseAllocation-btn {
    padding-top: 40px;
    gap: 20px;
}

.modal-addExercise {
    width: 40%;
}

.add-exercise-form {
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* Semi-transparent black overlay */
    z-index: 9999;
}

.modal {
    background-color: #ffffff;
    /* White background for modal */
    width: 50%;
    /* Adjust the width as needed */
    padding: 40px;
    border-radius: 8px;
}

.modal-content {
    display: flex;
    flex-direction: column;
}

.input-container-add-exe {
    margin-bottom: 20px;
}

.input-container-add-exe label {
    margin-bottom: 5px;
}

.button-container-add-exe {
    display: flex;
    justify-content: center;
}

.input-container-add-exe {
    margin-bottom: 20px;
}

.input-container-add-exe label {
    margin-bottom: 5px;
    display: block;
}

.input-container-add-exe input[type="text"],
.input-container-add-exe input[type="number"],
.input-container-add-exe textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    box-sizing: border-box;
}

.input-container-add-exe input[type="text"] {
    height: 40px;
}

.input-container-add-exe input[type="number"] {
    width: 50%;
    height: 40px;
}

.input-container-add-exe input[type="text-1"] {
    width: 160%;
    height: 32px;
}

.input-container-add-exe textarea {
    resize: vertical;
}

.main-input-container {
    display: flex;
    justify-content: space-between
}