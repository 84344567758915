.container {
  text-align: center;
  background-color: rgba(251, 250, 250, 1);
  box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  border-radius: 4px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 7px;
  padding: 32px;
}

@media only screen and (max-width: 1040px) {
  .container {
    width: 100%;
  }
}

.chatLink {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  background-color: #25d366;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.whatsappIcon {
  margin-right: 10px;
  width: 24px;
  height: 24px;
  vertical-align: middle;
}

.chatcontainer {
  padding-bottom: 20px;
}