#solutions {
  text-align: center;
  padding: 20px 0 60px;
}

.s {
  padding: 50px 100px;
}

.s-heading {
  font-size: 52px;
  font-weight: 500;
  margin-bottom: 44px;
}

.s-slides {
  display: flex;
  flex-direction: column;
}

.s-box {
  width: 100%;
  margin: 10px;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  background-color: #fff;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}

.s-image {
  max-width: 50%;
  height: auto;
}

.s-title {
  font-size: 34px;
  margin-top: 10px;
  padding-bottom: 20px;
  font-weight: 700;
}

.s-description {
  list-style-type: disc;
  padding-left: 20px;
  text-align: left;
  display: flex;
  flex-direction: row;
}

.s-list {
  padding-top: 8px;
  font-size: 18px;
}

@media screen and (max-width: 480px) {
  .s {
    padding: 20px;
  }

  .s-heading {
    font-size: 36px;
  }

  .s-box {
    margin: 10px 0;
    padding: 10px;
    width: fit-content;
  }

  .s-title {
    font-size: 24px;
  }

  .s-image {
    display: none;
  }
}