.DocExerciseAllocation {
  background-color: rgba(255, 255, 255, 1);
  display: flex;
  flex-direction: column;
  width: 100vw;
  padding: 0px;
  padding-top: 20px;
}

.DocExerciseAllocation-btn {
  display: flex;
  align-items: center;
  justify-content: center;
}


.Items {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 30px;
  padding: 0;
  box-sizing: border-box;
  align-self: stretch;
}

.EventCard {
  flex: 0 0 calc(33.33% - 30px);
  max-width: calc(33.33% - 30px);
}


@media screen and (max-width: 1024px) {
  .Items {
    flex-direction: row;
  }

  .EventCard {
    flex: 0 0 calc(100% - 20px);
    max-width: calc(100% - 20px);
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 768px) {
  .EventCard {
    flex: 0 0 calc(100% - 20px);
    max-width: calc(100% - 20px);
    margin-bottom: 20px;
  }
}