.EventCard {
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0px 4px 6px rgba(157, 157, 159, 0.2);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 14px;
    padding: 0px;
    box-sizing: border-box;
    flex-grow: 1;
    overflow: hidden;
}

.EventCard:hover {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.4);
    cursor: pointer;
}

.Image {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    align-self: stretch;
    height: 171px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Image img {
    max-width: 100%;
    max-height: 140%;
    object-fit: cover;
}

.TextButton {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 14px;
    padding: 0px 40px 30px 28px;
    box-sizing: border-box;
    align-self: stretch;
}

.Details {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 2px;
    padding: 0px;
    box-sizing: border-box;
    align-self: stretch;
}

.ItemName {
    text-align: left;
    white-space: pre-wrap;
    color: rgba(0, 0, 0, 1);
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    letter-spacing: 0px;
    text-decoration: none;
    text-transform: none;
    align-self: stretch;
}

.ItemPrice {
    text-align: left;
    white-space: pre-wrap;
    color: rgba(0, 0, 0, 1);
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 0px;
    text-decoration: none;
    text-transform: none;
}