.big-div {
    align-items: center;
    aspect-ratio: 1;
    display: flex;
    justify-content: center;
    left: 25%;
    max-width: 56%;
    position: absolute;
    width: 56%;
}

.container-img {
    aspect-ratio: 1;
    max-width: 50%;
    position: relative;
    width: 50%;
}

.centered-image {
    max-width: 100%;
    max-height: 180%;
}

.centered-image.back-view {
    padding-left: 12px;
}


.points {
    aspect-ratio: 1;
    background: #66CD8F;
    border-radius: 50%;
    cursor: pointer;
    opacity: 0.6;
    position: absolute;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 18px;
}

.points::before {
    content: attr(data-tooltip);
    position: absolute;
    background-color: black;
    color: white;
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 12px;
    opacity: 0;
    pointer-events: none;
    white-space: nowrap;
    transition: opacity 0.3s ease;
    top: -24px;
    left: 50%;
    transform: translateX(-50%);
}

.points:hover {
    background: rgba(220, 63, 63, 0.9);
}

.points:hover::before {
    opacity: 1;
    background-color: black;
}

.neck {
    left: 51%;
    top: 17%;
}

.left-elbow {
    left: 23%;
    top: 36%;
}

.right-elbow {
    left: 77%;
    top: 36%;
}

.left-shoulder {
    left: 32%;
    top: 26%;
}

.right-shoulder {
    left: 69%;
    top: 26%;
}

.lower-back {
    left: 51%;
    top: 41%;
}

.left-hip {
    left: 44%;
    top: 47%;
}

.right-hip {
    left: 58.5%;
    top: 47%;
}

.left-knee {
    left: 37%;
    top: 70%;
}

.right-knee {
    left: 64%;
    top: 70%;
}

.left-ankle {
    left: 34%;
    top: 87%;
}

.right-ankle {
    left: 67%;
    top: 87%;
}

.toggle-switch {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
}