.FeedbackBar1 {
  background: linear-gradient(90deg, #68cd8f, #14a2b1);
  border: 1px solid rgba(176, 176, 176, 0.5);
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 28px 38px;
  padding-left: 14px;
  font-weight: 700;
  font-size: 28px;
  color: white;
}

@media only screen and (max-width: 600px) {
  .FeedbackBar1 {
    padding: 28px 12px;
  }
}

.FeedbackText {
  color: white;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
}

/* .Btm {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    box-sizing: border-box;
    align-self: stretch;
  } */

.Frame3 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Reaction1 {
  width: 221px;
  height: 42px;
}