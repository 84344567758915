.webpage-header {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 8999;
  display: flex;
  justify-content: space-between;
  padding: 12px 16px;
  font-weight: 500;
  box-shadow: 0 1px 6px rgba(169, 188, 218, 0.65);
  background: linear-gradient(90deg, #68cd8f, #14a2b1);
  color: white;
}

.webpage-header-left {
  /* font-family: 'Sen'; */
  font-size: 24px;
  font-weight: 500;
}

.webpage-header-left:hover {
  cursor: pointer;
}

.webpage-header-right {
  display: flex;
  align-items: center;
}

.mobile-menu-icon {
  display: none;
  cursor: pointer;
  font-size: 24px;
  margin-right: 10px;
}

.header-menu {
  display: flex;
}

.header-menu-item {
  padding: 4px 20px;
  text-decoration: none;
  color: white;
  transition: background-color 0.3s;
}

.header-menu-item:hover {
  background-color: rgba(255, 255, 255, 0.1);
  cursor: pointer;
  border-radius: 8px;
}


@media (max-width:1000px) {
  .mobile-menu-icon {
    display: flex;
  }

  .header-menu {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 44px;
    left: 0;
    background-color: #333;
    width: 100%;
    z-index: 9000;
  }

  .mobile-menu-open {
    display: flex;
    background: linear-gradient(90deg, #68cd8f, #14a2b1);
  }

  .header-menu-item {
    padding: 10px 20px;
    text-align: center;
  }
}