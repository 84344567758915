.property1-default {
  display: flex;
  flex-direction: row;
  width: 268px;
  height: 40px;
  justify-content: flex-start;
  align-items: center;
  gap: 13px;
  padding: 0px 32px 0px 12px;
  box-sizing: border-box;
  cursor: pointer;
}

.property1-default {
  background-color: #66cd8f;
}

.property1-default.sidebar-hover[data-isselected="true"] {
  background-color: #3eb7a0;
}

.property1-default.sidebar-hover[data-isselected="true"][data-ishover="true"] {
  background-color: #14a2b0;
}

.text {
  text-align: left;
  white-space: pre-wrap;
  color: rgba(255, 255, 255, 1);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  letter-spacing: 0.64px;
  text-decoration: none;
  text-transform: none;
  flex-grow: 1;
}