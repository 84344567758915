.TypeQuestSidebarOpen {
    background-color: rgba(255, 255, 255, 1);
    display: flex;
    flex-direction: column;
    width: 100vw;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0px;
    box-sizing: border-box;
}

.Page {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0px;
    box-sizing: border-box;
    align-self: stretch;
    height: 100%;
}

.Sidebar1 {
    align-self: stretch;
    width: 290px;
}

.ContentDoctor {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 48px;
    height: 100vh;
    overflow: auto;
    padding-left: 10px;
    /* margin-right: 0px;
    margin-left: 90px; */
    padding-right: 10px;
}

.Content::-webkit-scrollbar {
    display: none;
}

@media only screen and (max-width: 1000px) {
    .Content {
        margin: 0 15px 30px 15px;
        height: 140vh;
    }
}

.NavBar1 {
    align-self: stretch;
    height: 80px;
}

.Sec1 {
    padding-top: 0%;
    align-self: stretch;
}

@media only screen and (max-width: 600px) {
    .Sec1 {
        padding: 20px 18px;
        padding-top: 0%;
    }
}

.WelcomeText {
    font-weight: 700;
    font-size: 28px;
}

.FeedbackBar1 {
    align-self: stretch;
}

.Sec2 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    box-sizing: border-box;
    align-self: stretch;
    gap: 20px;
}

@media only screen and (max-width: 1040px) {
    .Sec2 {
        flex-direction: column;
    }
}

.Sec3-doc {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 20px;
    box-sizing: border-box;
    align-self: stretch;
}

@media only screen and (max-width: 1000px) {
    .Sec3-doc {
        padding: 0 0 7px 12px;
    }
}

.patientsList {
    text-align: left;
    white-space: pre-wrap;
    color: rgba(0, 0, 0, 1);
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    letter-spacing: 0px;
    text-decoration: none;
    text-transform: none;
}

.Items-doc {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 30px;
    padding: 0px;
    box-sizing: border-box;
    align-self: stretch;
}


@media only screen and (max-width: 1000px) {
    .Items-doc {
        flex-direction: column;
        padding-left: 12px;
        width: 94%;
    }
}

.Sec4 {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
    align-self: stretch;
    height: 406px;
}

.Sec3-subset {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.add-patient {}