.docFeedbackBar {
    background: linear-gradient(90deg, #68cd8f, #14a2b1);
    border: 1px solid rgba(176, 176, 176, 0.5);
    box-sizing: border-box;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 28px 38px;
    padding-left: 14px;
    font-weight: 700;
    font-size: 28px;
    color: white;
}

@media only screen and (max-width: 600px) {
    .docFeedbackBar {
        padding: 28px 12px;
    }
}