.MainDiv {
  display: flex;
  flex-wrap: wrap;
  margin-left: 100px;
  overflow-y: auto;
}

.MainDiv::-webkit-scrollbar {
  display: none;
}

.ProgressBarDiv {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 20px;
  padding-left: 4px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.progress-bar-container {
  padding: 20px;
  padding-bottom: 40px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

.progress-bar-container-two {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
}

.progress-bar-container-two h1 {
  margin: 0;
}

.CircularProgressbar {
  height: 150px;
  padding-bottom: 20px;
  filter: drop-shadow(0 0 5px rgba(0, 255, 255, 0.8));
}

.side-image {
  max-width: 40%;
  height: auto;
  border-radius: 8px;
}

.progress-bar-container-one {
  display: flex;
  align-items: center;
}

.path-color-neon {
  stroke: #00ffea;
}

.text-color-neon {
  fill: #00ffea;
}

.progress-bar-container-one .CircularProgressbar .CircularProgressbar-path {
  stroke: #00ffea;
}

.progress-bar-container-one .CircularProgressbar .CircularProgressbar-trail {
  stroke: #d6d6d6;
}

.progress-bar-container-one .CircularProgressbar .CircularProgressbar-text {
  fill: #00ffea;
}

.line-chart-container {
  display: flex;
  flex-direction: column;
  width: 65%;
  height: 100%;
}

.progressDiv {
  display: flex;
  flex-direction: column;
  padding-left: 70px;
}

@media (max-width: 768px) {
  .ProgressBarDiv {
    flex-direction: column;
    align-items: center;
  }

  .progress-bar-container {
    width: 100%;
  }
}

.botton-back-btn {
  display: flex;
  justify-content: center;
  align-items: center;
}