.i {
  display: flex;
  height: 95vh;
  background: linear-gradient(90deg, #68cd8f, #14a2b1);
  color: white;
  /* height: 94vh; */
}

.i-left {
  flex: 2;
  display: flex;
  align-items: center;
  justify-content: center;
}

.i-right {
  flex: 1;
  position: relative;
  overflow: hidden;
}

.i-left-wrapper {
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.i-intro {
  font-size: 52px;
  font-weight: 700;
}

.i-name {
  font-size: 60px;
}

.i-title {
  height: 50px;
  overflow: hidden;
}

.i-title-wrapper {
  height: 100%;
  animation: move 10s ease-in-out infinite alternate;
}

@keyframes move {
  25% {
    transform: translateY(-50px);
  }
  50% {
    transform: translateY(-100px);
  }
  75% {
    transform: translateY(-150px);
  }
  100% {
    transform: translateY(-200px);
  }
}

.i-title-item {
  height: 50px;
  font-size: 30px;
  font-weight: bold;
  color: #59b256;
  display: flex;
  align-items: center;
}

.i-scroll {
  width: 30px;
  height: 30px;
  position: absolute;
  bottom: 20px;
}

.desktop-img {
  width: 250%;
  height: 140%;
  object-fit: scale-down;
  position: absolute;
  /* transform: rotate(15deg); */
  left: -80%;
  top: -20%;
}

.mobile-img {
  display: none;
}

.i-desc{
  padding-top: 40px;
  font-size: 28px;
}


.i-desc-p{
  padding-top: 8px;
  padding-left: 10px;
}

.i-desc-p::before {
  content: "\2022"; 
  display: inline-block;
  margin-right: 10px;
}

.image-container {
  display: flex;
}


@media screen and (max-width: 480px) {
  .i {
    flex-direction: column;
    height: auto;
  }

  .i-intro{
    font-size: 40px;
    padding: 28px 0;
  }

  .i-left-wrapper {
  
    padding: 10px;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 100%;
    align-items: center;
  }

  .icon-left{
    display: flex;
    flex-direction: column;
  }

  .i-title-item{
    font-size: 25px;
  }

  .i-title-item{
    display: flex;
    justify-content: revert;
    flex-direction: column;
  }

  .i-desc{
    font-size: 24px;
    padding: 20px 0;
  }
  
  .i-right{
    display: flex;
  }

  .mobile-img {
    /* width: 250%;
    height: 140%; */
    display: flex;
    object-fit: scale-down;
    width: 90%;
    max-width: 100%;
    /* height: auto; */
  }
  .desktop-img {
    display: none;
  }
}
