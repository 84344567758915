.main {
    display: flex;
    flex-direction: column;
}

.header-login {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    background-image: #fff;
    gap: 0px;
    font-weight: 500;
}

.app {
    background: white;
    display: flex;
    flex-direction: row;
    gap: 20px;
    height: 90vh;
    justify-content: space-between;
}

input[type="text"],
input[type="password"] {
    height: 25px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 4px;
}

input[type="submit"] {
    margin-top: 10px;
    cursor: pointer;
    font-size: 15px;
    background: #01d28e;
    border: 1px solid #01d28e;
    color: #fff;
    padding: 10px 20px;
}

input[type="submit"]:hover {
    background: #6cf0c2;
}

.button-container {
    display: flex;
    justify-content: center;
    padding-top: 20px;
}

.login-form {
    background-image: linear-gradient(90deg, #68cd8f, #14a2b1);
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    height: 250px;
    color: #fff;
}

.right-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 80px;
    padding-top: 40px;
}

.list-container {
    display: flex;
}

.error {
    color: red;
    font-size: 12px;
}

.title {
    font-size: 30px;
    font-weight: 500;
    margin-bottom: 20px;
}

.input-container {
    display: flex;
    flex-direction: column;
    font-weight: 500;
    gap: 8px;
    margin: 10px;
}

.taglines {
    color: #000000;
    gap: 0;
}

.left-container {
    display: flex;
    padding-left: 10px;
    flex-direction: column;
    flex: 1 1;
    padding-top: 60px;
}

.heading {
    font-size: 60px;
    font-weight: 700;
}

.tagline {
    font-size: 28px;
    font-weight: 500;
    padding-top: 20px;
}

.intro-img {
    width: 90%;
    transform: rotate(-7deg);
    /* Rotate 20 degrees to the left */
}


.image-sub {
    overflow: hidden;
    position: relative;
    padding-top: 7dvb;
}

.line {
    width: 40%;
    height: 3px;
    background-image: linear-gradient(90deg, #68cd8f, #14a2b1);
}

.tagline-3 {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.left-side {
    display: flex;
    align-items: center;
}

.header-btn {
    padding-right: 10px;
}