.modal-left {
    position: fixed;
    left: 24%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
}

.modal-right {
    position: fixed;
    right: 19%;
    top: 50%;
    transform: translate(50%, -50%);
    background-color: white;
    padding: 20px;
}

.joint-image {
    max-width: 80px;
}

.modal-content {
    display: flex;
    flex-direction: column;
}

.close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}

.modal-joints {
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0px 4px 6px rgba(157, 157, 159, 0.8);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    padding: 0px 10px;
    box-sizing: border-box;
    flex-grow: 1;
    overflow: hidden;
    height: 30%;
    width: 26%;
    max-width: 26%;

}

.joint-name {
    font-size: 30px;
    font-weight: 700;
    padding-bottom: 10px;
}

.joint-exercise-name {
    font-size: 18px;
    font-weight: 500;
    padding-bottom: 10px;
}

.joint-exercise-name:hover {
    cursor: pointer;
    transform: translateY(-3px);
    /* Move the text 3px up on hover */
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
    /* Add shadow to text on hover */
}

.joint-exercise {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.joint-upper-div {
    display: flex;
    flex-direction: column;
    align-items: center;
}