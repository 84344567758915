.a {
  height: 95vh;
  display: flex;
  align-items: center;
  color: white;
  background: linear-gradient(90deg, #DEE7EE, #14A2B1);
}

.a-left {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 100%;
}

.a-right {
  flex: 1.2;
}

.a-card.bg {
  position: absolute;
  top: 50px;
  left: 50px;
  background-color: white;
}

.a-card {
  width: 60%;
  height: 70vh;
  border-radius: 30px;
  position: relative;
  overflow: hidden;
  background-color: white;
}

.a-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.a-award-img {
  width: 120px;
  height: 120px;
  border-radius: 20px;
}

.a-title {
  font-size: 52px;
  font-weight: 500;
  margin-bottom: 44px;
  display: flex;
}

.a-sub {
  margin: 20px 0px;
}

.a-award{
    margin-top: 34px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.a-award-texts{
    width: 70%;
}

.a-desc{
  padding-top: 8px;
  padding-left: 10px;
  font-size: 28px;
}

.a-desc::before {
  content: "\2022"; 
  display: inline-block;
  margin-right: 10px;
}

@media screen and (max-width:480px) {
    .a{
      height: 120vh;
      flex-direction: column;
      text-align: center;
      margin-top: 50px;
      background: linear-gradient(90deg, #B2D5DE, #14A2B1);
    }

    .a-title{
      font-size: 40px;
    }

    .a-left {
      width: 100%;
    }

    .a-card {
      height: 30vh;
    }

    .a-card.bg {
      display: none;
    }

    .a-right{
      padding: 20px;
      font-size: 28px;
      margin-bottom: 30px;
    }

    .a-desc{
      font-size: 24px;
    }
}

.company-link{
  color: green;
  text-decoration: none;
}

.myButton {
	border-radius:28px;
	display:inline-block;
	cursor:pointer;
	font-family:Arial;
	font-size:17px;
	text-decoration:none;
  color: #555;
}
