.profile-container {
    margin: 20px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    background-color: #fff;
}

.main-profile {
    display: flex;
    flex-direction: row;
}

.left-side-user {
    display: flex;
    flex-direction: column;
}

.user-details {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;

}

.clinical-conditions {
    margin-bottom: 20px;
    padding-top: 50px;
}

.progress-reports {
    margin-bottom: 20px;
}

ul {
    list-style-type: none;
    padding: 0;
}

li {
    margin-bottom: 10px;
}

strong {
    font-weight: 500;
}

.p-details {
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.clinical-conditions-sub {
    display: flex;
    align-items: center;
}

.clinical-conditions-img {
    width: 10%;
}

.clinical-conditions-name {
    font-size: 20px;
    font-weight: 600;
}

h2 {
    text-decoration: underline;
    padding-bottom: 10px;
}

h1 {
    text-decoration: underline;
    padding-bottom: 30px;
}

.user-graph {
    padding-top: 20px;
}

.user-graph-bottom {
    padding-top: 20px;
}

.user-profile-buttons {
    padding-top: 120px;
    display: flex;
    justify-content: center;
    gap: 10px;
}