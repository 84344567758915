.collapsable-open {
  background-color: #66cd8f;
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 290px;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0px;
  box-sizing: border-box;
  overflow: hidden;
  position: absolute;
  z-index: 999;
  transition: width 0.3s ease;
}

@media screen and (max-width: 1000px) {
  .collapsable-open {
    display: none;
  }
}

.moonbase-logo,
.moonbase-logo-m {
  font-size: 24px;
  color: white;
  font-weight: 550;
}

.moonbase-logo {
  padding-left: 20px;
}

.image-logo {
  width: 50px;
  height: 50px;
}


.top {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 56px;
  padding: 20px 0px 0px 0px;
  box-sizing: border-box;
  align-self: stretch;
}

.logo {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 0px 0px 40px;
  box-sizing: border-box;
  transition: opacity 0.3s ease;
}

.moonbase-logo {
  height: 50px;
}

.moonbase-logo-m {
  height: 25px;
  width: 20px;
}

.list {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 6px;
  padding: 0px 0px 0px 22px;
  box-sizing: border-box;
  align-self: stretch;
}

.sidebar-row-1 {
  align-self: stretch;
  height: 40px;
}

.account-1 {
  background-color: #3eb7a0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  padding: 0px;
  box-sizing: border-box;
  align-self: stretch;
  height: 60px;
}

.avatar-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  padding: 0px 30px;
  box-sizing: border-box;
  align-self: stretch;
  text-decoration: none;
}

.avatar-1 {
  width: 32px;
  height: 32px;
}

.full-name {
  text-align: left;
  white-space: pre-wrap;
  color: rgba(255, 255, 255, 1);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  letter-spacing: 0.64px;
  text-decoration: none;
  text-transform: none;
  flex-grow: 1;
}

.top {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 56px;
  padding: 20px 0px 0px 0px;
  box-sizing: border-box;
  align-self: stretch;
}

.logo {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 0px 0px 40px;
  box-sizing: border-box;
}

.moonbase-logo {
  height: 50px;
}

.moonbase-logo-m {
  height: 25px;
  width: 20px;
}

.list {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 6px;
  padding: 0px 0px 0px 22px;
  box-sizing: border-box;
  align-self: stretch;
}

.sidebar-row-1 {
  align-self: stretch;
  height: 40px;
}

.account-1 {
  background-color: #3eb7a0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  padding: 0px;
  box-sizing: border-box;
  align-self: stretch;
  height: 60px;
}

.avatar-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  padding: 0px 30px;
  box-sizing: border-box;
  align-self: stretch;
}

.avatar-1 {
  width: 32px;
  height: 32px;
}

.full-name {
  text-align: left;
  white-space: pre-wrap;
  color: rgba(255, 255, 255, 1);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  letter-spacing: 0.64px;
  text-decoration: none;
  text-transform: none;
  flex-grow: 1;
}

.sidebar-link {
  text-decoration: none;
}