.NavBar1 {
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-end;
}

.image-logo {
  width: 50px;
  height: 50px;
}

@media screen and (max-width: 1000px) {
  .NavBar1 {
    display: none;
  }
}

.Account {
  display: flex;
  align-items: center;
  gap: 18px;
  padding: 6px 0px;
  text-decoration: none;
  color: black;
}

.ProfilePic {
  font-weight: 700;
  font-size: 16px;
  letter-spacing: 0.64px;
}